@if (store.taxpayer()?.isMfaEnable) {
  <div class="otp-verification-container">
    <div class="otp-verification-box">
      <div class="mb-4">
        <img [src]="store.brandMeta()?.logo" [alt]="store.brandMeta()?.shortName" width="70" />
      </div>

      <div>
        <form [formGroup]="mfaOtpValidationForm" (submit)="verifyMfaOtp()">
          <h1 class="tw-text-lg tw-font-semibold mb-1">OTP Verification</h1>
          <p class="help-text">
            Please enter the OTP (one-time-password) sent to your registered email ID to complete your verification.
          </p>
          <div class="tw-flex-1">
            <div class="form-group mt-4">
              <label>Email Address</label>
              <input type="email" class="form-control disabled" formControlName="userId" />
            </div>
            <div class="form-group tw-flex tw-items-center mt-2">
              @if (!showTimer) {
                <label class="timer tw-mb-0 tw-text-xs">Didn't get the OTP?</label>
                <button
                  type="button"
                  class="text-link btn-xs p-0 text-primary tw-text-xs tw-ml-1"
                  [class]="{
                    '!tw-cursor-not-allowed': isMfaUserTemporaryDisable,
                  }"
                  (click)="getOTP($event)"
                  [disabled]="isMfaUserTemporaryDisable"
                >
                  Resend OTP
                </button>
              } @else {
                <label class="timer">Resend OTP in {{ otpExpirationTime }}s</label>
              }
            </div>
            <div class="form-group mt-4">
              <label class="tw-mt-4">OTP (One Time Password)</label>
              <input type="text" class="form-control" formControlName="otp" placeholder="OTP" required />

              <div class="input-error">
                @if (mfaOtpValidationForm.controls['otp'].errors) {
                  <p>OTP is invalid or empty</p>
                }
              </div>
            </div>

            <div class="form-group mt-4">
              <button [disabled]="mfaOtpValidationForm.invalid" class="btn btn-primary full">Verify OTP</button>

              <div class="mt-3 text-center">
                <button type="button" class="btn-link btn-sm" (click)="goBackToLogin()">&#8592; Back to Login</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
} @else {
  <div class="login-container">
    <div class="wrapper">
      <div class="login-box">
        <div class="mb-4">
          <img [src]="store.brandMeta()?.logo" [alt]="store.brandMeta()?.shortName" width="90" class="m-auto" />
        </div>

        <div>
          <form [formGroup]="loginForm" (submit)="verifyCaptcha()">
            <h1 class="tw-text-xl tw-font-semibold mb-4 text-center">Welcome back!</h1>

            <label class="cap">Email Address</label>
            <input
              required
              type="email"
              class="form-control tw-mb-4"
              placeholder="name@example.com"
              formControlName="userId"
              required
            />

            <label class="cap">Password</label>
            <div class="password-container mb-4">
              <input
                [type]="passwordFieldType"
                formControlName="password"
                class="form-control"
                placeholder="Password"
                required
              />
              <button type="button" class="password-toggle" (click)="togglePasswordVisibility()">
                @if (passwordFieldType === 'password') {
                  <ng-icon name="matVisibilityOff" size="16" />
                } @else {
                  <ng-icon name="matRemoveRedEye" size="16" />
                }
              </button>
            </div>

            @if (passwordExpired) {
              <div class="alert alert-danger tw-text-sm tw-mb-4">
                <p class="tw-font-bold mb-2">Password Expired</p>
                <p>
                  Your current password has expired and must be changed. Passwords are valid for a period of 90 days.
                </p>
                <a class="tw-inline-block text-link !tw-text-red-700 mt-2" routerLink="/forget-password">
                  Reset Password
                </a>
              </div>
            } @else {
              <button [disabled]="loginForm.invalid" class="btn btn-primary tw-py-3 full">LOG IN</button>
              <div class="mt-3 text-center">
                <button class="btn-link tw-text-sm" (click)="forgetPassword()">Forgot Password?</button>
              </div>
            }
          </form>
        </div>
      </div>

      <div class="footer">
        <p class="mb-1 tw-text-gray-400 tw-text-xs">Product Prerequisite</p>
        <a class="mb-2" href="mailto:support@myeinvois.my">support&#64;myeinvois.my</a>
        <div>
          <a href="https://myeinvois.my/privacy-policy/" target="_blank">Privacy Policy</a>
          &bull;
          <a href="https://myeinvois.my/terms-conditions/" target="_blank">Terms of Service</a>
        </div>
      </div>
    </div>
  </div>
}

<div class="app-body tw-relative">
  <app-header class="header" />
  <div class="tw-flex tw-relative">
    @if (!store.sideNavHide()) {
      <app-sidenav
        [app]="{ name: 'Settings', icon: 'matSettingsOutline' }"
        [menu]="sidenavMenu"
        [search]="false"
      />
    }
    <div class="tw-flex-1 tw-min-w-0 page-wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

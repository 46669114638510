import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Store } from '@app/config/store';
import {
  email,
  noSpacesValidator,
  otp,
  password,
  passwordMatchValidator,
} from '@app/dto/common.dto';
import { AuthService } from '@app/services/auth.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { matRemoveRedEye, matVisibilityOff } from '@ng-icons/material-icons/baseline';

@Component({
  selector: 'app-forget-password',
  standalone: true,
  imports: [ReactiveFormsModule, RouterModule, NgIconComponent],
  providers: [
    provideIcons({
      matRemoveRedEye,
      matVisibilityOff,
    }),
  ],
  templateUrl: './forget-password.component.html',
  styleUrl: './forget-password.component.css',
})
export class ForgetPasswordComponent implements OnInit {
  showTimer: boolean = false;
  otpExpirationTime: number = 30;
  passwordFieldType: string = 'password';
  passwordMatch: boolean = false;
  forgotPasswordForm: FormGroup = new FormGroup(
    {
      userId: new FormControl('', [email]),
      newPassword: new FormControl('', [password]),
      confirmNewPassword: new FormControl('', [password]),
      otp: new FormControl('', [noSpacesValidator(), otp]),
    },
    { validators: passwordMatchValidator() },
  );

  constructor(
    protected store: Store,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['email']) {
        this.forgotPasswordForm.patchValue({ userId: params['email'] });
      }
    });
  }

  getOTP(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.authService.getOTP(this.forgotPasswordForm.value).subscribe((res) => {
      if (res.status) this.startTimer();
    });
  }

  resetPassword() {
    this.authService.resetPassword(this.forgotPasswordForm.value).subscribe((res) => {
      if (res.status) {
        this.router.navigate(['/login']);
      }
    });
  }

  startTimer() {
    this.showTimer = true;
    const timeInterval = setInterval(() => {
      if (this.otpExpirationTime === 0) {
        clearInterval(timeInterval);
        this.showTimer = false;
        this.otpExpirationTime = 30;
      }
      this.otpExpirationTime = this.otpExpirationTime - 1;
    }, 1000);
  }
  checkPasswordMatch() {
    this.passwordMatch =
      this.forgotPasswordForm.controls['confirmNewPassword'].value ===
      this.forgotPasswordForm.controls['newPassword'].value;
  }

  togglePasswordVisibility() {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }
}

<div class="password-container">
  <div class="password-box">
    <div class="mb-4">
      <img [src]="store.brandMeta()?.logo" [alt]="store.brandMeta()?.shortName" width="80" />
    </div>

    <div>
      <form [formGroup]="passwordForm" (submit)="setPassword()">
        <h1 class="tw-text-xl tw-font-semibold mb-1">Welcome Aboard!</h1>
        <p class="tw-text-sm tw-text-gray-500 mb-4">Set password for your account!</p>

        <label>Email Address</label>
        <input
          required
          type="email"
          class="form-control"
          placeholder="name@example.com"
          formControlName="email"
          [disabled]="true"
        />

        <label class="mt-3">New Password</label>
        <div class="input-prefix password">
          <input
            [type]="passwordFieldType"
            formControlName="password"
            class="form-control"
            placeholder="Password"
            required
          />

          <button type="button" class="password-toggle" (click)="togglePasswordVisibility()">
            @if (passwordFieldType === 'password') {
              <ng-icon name="matVisibilityOff" size="16" />
            } @else {
              <ng-icon name="matRemoveRedEye" size="16" />
            }
          </button>
        </div>
        @if (passwordForm.controls['password'].dirty && passwordForm.controls['password'].errors) {
          <p class="input-error">
            Passwords must contain at least eight characters, including uppercase, lowercase letters, numbers, and
            special characters (!&#64;#$%^?_)
          </p>
        }

        <label class="mt-3">Confirm Password</label>
        <input type="password" formControlName="confirmPassword" class="form-control" placeholder="Password" required />
        <div class="input-error">
          @if (passwordForm.errors?.['passwordMismatch']) {
            <p>Passwords do not match.</p>
          }
        </div>

        <button [disabled]="!passwordForm.valid" class="mt-4 tw-py-3 btn btn-primary full">Save Password</button>
      </form>
    </div>
  </div>
</div>

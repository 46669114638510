import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-error-403',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './error-403.component.html',
  styleUrl: './error-403.component.css',
})
export class Error403Component {}

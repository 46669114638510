import { Component, TemplateRef, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@app/config/store';
import { HeaderComponent } from '@app/shared/header/header.component';
import { Menu, SidenavComponent } from '@app/shared/sidenav/sidenav.component';
import { EinvoiceCreateComponent } from './einvoice-create/einvoice-create.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FSLUG, MSLUG } from '@app/constant/features';

@Component({
  selector: 'app-taxpayer',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, SidenavComponent, EinvoiceCreateComponent],
  templateUrl: './taxpayer.component.html',
  styleUrl: './taxpayer.component.css',
})
export class TaxpayerComponent {
  @ViewChild('createEinvoice') createEInvoice!: TemplateRef<HTMLElement>;

  sidenavMenu: Menu[] = [
    // {
    //   title: '',
    //   route: '',
    //   icon: '',
    //   menu: [
    //     {
    //       title: 'Dashboard',
    //       route: '/taxpayer/dashboard',
    //       icon: 'matDashboardOutline',
    //       class: '!tw-mt-1',
    //     },
    //   ],
    // },
    {
      title: 'E-Invoices',
      route: '',
      icon: '',
      menu: [
        {
          title: 'Generate',
          icon: 'matSellOutline',
          click: () => {
            this.ngbModal.open(this.createEInvoice, {
              backdrop: 'static',
              centered: true,
            });
          },
          access: this.store.userAccess()[FSLUG.EINVOICE_INVOICES_MODIFY],
        },
        {
          title: 'Bulk Generate',
          route: '/taxpayer/einvoices/bulk-upload',
          icon: 'matUploadFileOutline',
          access: this.store.userAccess()[FSLUG.EINVOICE_BULKGENERATE_VIEW],
        },
        {
          title: 'My E-Invoices',
          route: '/taxpayer/einvoices/list',
          icon: 'matArticleOutline',
          access: this.store.moduleAccess()[MSLUG.EINVOICE_LIST],
        },
      ],
    },
    {
      title: 'OCR Extraction',
      route: '',
      icon: '',
      menu: [
        {
          title: 'OCR Jobs',
          route: '/taxpayer/ocr/jobs',
          icon: 'matInventoryOutline',
          access: this.store.userAccess()[FSLUG.EINVOICE_INVOICES_MODIFY],
        },
        {
          title: 'Templates',
          route: '/taxpayer/ocr/templates',
          icon: 'matPictureAsPdfOutline',
          access: this.store.userAccess()[FSLUG.EINVOICE_OCR_TEMPLATES_VIEW],
        },
      ],
    },
    {
      title: 'Logs',
      route: '',
      icon: '',
      menu: [
        // {
        //   title: 'Sales Overview',
        //   route: '/taxpayer/reports/sales',
        //   icon: 'matStackedLineChart',
        // },
        // {
        //   title: 'Errors & Discrepancies',
        //   route: '/taxpayer/reports/errors',
        //   icon: 'matWarningAmber',
        // },
        {
          title: 'Email Logs',
          route: '/taxpayer/reports/email-logs',
          icon: 'matMailOutline',
          access: this.store.userAccess()[FSLUG.EINVOICE_EMAIL_LOGS_VIEW],
        },
        {
          title: 'Print Logs',
          route: '/taxpayer/reports/print-logs',
          icon: 'matPrintOutline',
          access: this.store.userAccess()[FSLUG.EINVOICE_PRINT_LOGS_VIEW],
        },
        {
          title: 'Submission Logs',
          route: '/taxpayer/reports/scheduler-logs',
          icon: 'matSchedule',
          access: this.store.userAccess()[FSLUG.EINVOICE_SCHEDULER_LOGS_VIEW],
        },
      ],
    },
    {
      title: 'Resources',
      route: '',
      icon: '',
      menu: [
        {
          title: 'Tools',
          route: '/taxpayer/tools/verify-tin',
          icon: 'matBuildOutline',
          access: this.store.userAccess()[FSLUG.EINVOICE_TOOLS_VIEW],
        },
        {
          title: 'Master Codes',
          route: '/taxpayer/codes',
          icon: 'matConstruction',
          access: this.store.userAccess()[FSLUG.EINVOICE_MASTER_CODES_VIEW],
        },
      ],
    },
  ];

  constructor(
    protected store: Store,
    private ngbModal: NgbModal,
  ) {}
}

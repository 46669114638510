<div class="sidenav {{ store.miniSidenav() ? 'minimize' : '' }}">
  <div class="menu-box">
    @if (app) {
      <div class="app-title">
        <ng-icon [name]="app.icon" size="20" />
        <h1>{{ app.name }}</h1>
      </div>
    }
    <nav class="navmenu tw-flex tw-flex-col">
      @if (search) {
        <button class="menu-item nav-btn-search" (click)="openSearch()">
          <ng-icon name="matSearchOutline" />
          <span>Search</span>
        </button>
      }

      @for (item of menu; track $index) {
        @if (item.menu) {
          <div class="menu-group">
            @if (item.title !== '') {
              <button class="group-title" (click)="toggleGroupMenu($index)">
                {{ item.title }}
                <i
                  [class]="{
                    'gg-chevron-down': item.collapse,
                    'gg-chevron-up': !item.collapse
                  }"
                ></i>
              </button>
            }

            <div [class]="{ collapse: item.collapse }">
              @for (child of item.menu; track $index) {
                @if (child.access) {
                  @if (child.click) {
                    <button class="menu-item" [class]="child.class" (click)="child.click()">
                      <ng-icon name="{{ child.icon }}" />
                      <span>{{ child.title }}</span>
                    </button>
                  } @else {
                    <a class="menu-item" [class]="child.class" routerLink="{{ child.route }}" routerLinkActive="active">
                      <ng-icon name="{{ child.icon }}" />
                      <span>{{ child.title }}</span>
                    </a>
                  }
                }
              }
            </div>
          </div>
        }
      }
    </nav>
  </div>

  <button class="btn-minimize" (click)="toggleMenu()">
    <div class="iconbox">
      @if (store.miniSidenav()) {
        <ng-icon name="matWidthFullOutline" />
      } @else {
        <ng-icon name="matArrowCircleLeftOutline" />
      }
    </div>
    <span>Minimize</span>
  </button>
</div>

<ng-template>
  <div class="searchbar">
    <ng-icon name="matSearchOutline" />
    <input type="text" class="form-control" placeholder="Search" [formControl]="searchText" (keyup)="handleSearch()" />
  </div>

  @if (searchResults.length) {
    <div class="search-results">
      @for (item of searchResults; track $index) {
        <div
          [tabindex]="{ $index }"
          class="search-item"
          (click)="onResultAction(item)"
          (keypress)="onResultAction(item)"
        >
          <div class="search-photo"></div>
          <div class="search-info">
            <p class="tw-font-semibold">{{ item.title }}</p>
            <p class="tw-text-xs tw-text-gray-400">{{ item.tagline }}</p>
          </div>
        </div>
      }
    </div>
  } @else if (searchText.value !== '' && searchResults.length < 1) {
    <div class="search-results tw-p-10 tw-flex tw-flex-col tw-gap-2">
      <i class="gg-inbox"></i>
      <p class="tw-text-center tw-text-gray-400 tw-text-xs">No matching results found.</p>
    </div>
  }
</ng-template>

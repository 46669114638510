/// <reference types="@angular/localize" />

/* eslint-disable-next-line @typescript-eslint/no-unsafe-function-type */
declare var loadGoogleTag: Function;

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from '@sentry/angular';
import { config } from '@app/config/app';
import { enableProdMode } from '@angular/core';

if (config.env === 'prod' || config.env === 'sandbox') loadGoogleTag(config.gTagId);

Sentry.init({
  release: 'malaysia-ui@' + config.version,
  environment: config.env,
  dsn: config.sentryDSN,
  //integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  autoSessionTracking: false,
  // Performance Monitoring
  tracesSampleRate: 0.2, //  Capture 20% of the transactions
  sampleRate: 0.7, //  Capture 70% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'dev.app.myeinvois.my',
    'sandbox.myeinvois.my',
    'prod.myeinvois.my',
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.7, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (!['local', 'development'].includes(config.env)) enableProdMode();
bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, map } from 'rxjs';
import { LoginResp } from './auth.service';

export interface SetPassword {
  email: string;
  password: string;
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class SetPasswordService {
  constructor(
    private httpClient: HttpClient,
    private toastr: ToastrService,
  ) {}

  setPassword(payload: SetPassword): Observable<LoginResp> {
    return this.httpClient.post('/auth/setpassword', payload).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((resp: any) => {
        if (resp.status) return resp.response;
        if (resp.fieldErrors.length) {
          this.toastr.error(resp.fieldErrors[0].message ?? 'Error');
          return null;
        }
        this.toastr.error(resp.message ?? 'Error');
        return null;
      }),
    );
  }
}

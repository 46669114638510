<div class="header">
  <a class="logo" routerLink="/taxpayer">
    <img [src]="store.brandMeta()?.logoInverted" [alt]="store.brandMeta()?.shortName" />
  </a>
  <div class="navmenu">
    @if (store.defaultRoute().einvoice) {
      <a class="navitem" [routerLink]="store.defaultRoute().einvoice" [class.active]="activeApp === 'taxpayer'">
        <ng-icon name="matReceiptLongOutline" /> E-Invoice
      </a>
    }

    @if (store.defaultRoute().garnet) {
      <a class="navitem" [routerLink]="store.defaultRoute().garnet" [class.active]="activeApp === 'garnet'">
        <ng-icon name="matTransformOutline" /> Transform
      </a>
    }

    <!-- <div ngbDropdown class="d-inline-block">
      <button class="navitem" id="ngb-apps-menu" ngbDropdownToggle>
        <i class="gg-menu-grid-o"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="ngb-apps-menu">
        <div class="apps-menu">
          <a class="appitem" routerLink="/garnet">
            <ng-icon name="matTransformOutline" />
            Transform
          </a>
          <a class="appitem" routerLink="/taxpayer">
            <ng-icon name="matReceiptLongOutline" />
            E-Invoice
          </a>
        </div>
      </div>
    </div> -->

    <div ngbDropdown class="d-inline-block">
      <button id="ngb-usermenu" class="navitem tw-max-w-36 !tw-pr-1" ngbDropdownToggle>
        <ng-icon name="matBusinessOutline" />
        <span class="ellipsis">{{ store.activeAccount()?.orgName }}</span>
        <i class="gg-chevron-down"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="ngb-usermenu" class="dropmenu">
        <div class="dropitem group user-info">
          <div class="user-avatar">
            <span>{{ store.taxpayer()?.name?.slice(0, 2) }}</span>
          </div>
          <div class="user-details">
            <div class="tw-font-semibold">{{ store.taxpayer()?.name }}</div>
            <div class="help-text ellipsis">
              {{ store.taxpayer()?.email }}
            </div>
          </div>
        </div>
        <button class="dropitem group flex-apart tw-border-b" (click)="openModal(switchAccountModal)">
          <div class="tw-text-left">
            <h3 class="tw-font-semibold">{{ store.activeAccount()?.orgName }}</h3>
            <p class="help-text">{{ store.activeAccount()?.roleName }}</p>
          </div>
          <ng-icon name="matUnfoldMoreOutline" size="16" />
        </button>
        <div class="group">
          @if (store.defaultRoute().settings) {
            <a class="dropitem" [routerLink]="store.defaultRoute().settings">
              <ng-icon name="matSettingsOutline" size="14" /> Settings
            </a>
          }
          <!-- <a class="dropitem"><ng-icon name="matDevicesOutline" size="14" /> API Docs</a>
          <a class="dropitem"> <ng-icon name="matLocalPoliceOutline" size="14" /> Terms & Policies </a> -->
          <button class="dropitem" (click)="openModal(changePassword, 'md')">
            <ng-icon name="matLockOutline" size="14" /> Change Password
          </button>
          <button class="dropitem" (click)="openModal(mfa, 'md')">
            <ng-icon name="matCheckCircleOutline" size="14" /> Multi Factor Authentication
          </button>
        </div>
        <button class="dropitem group tw-border-0 text-danger" (click)="store.logout()">
          <ng-icon name="matPowerSettingsNewOutline" /> Logout
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #changePassword>
  <app-change-password (closeEvent)="closeModal()" />
</ng-template>

<ng-template #switchAccountModal>
  <div class="switch-account-modal tw-py-2">
    <div class="flex-apart tw-p-4">
      <h2>Switch Account</h2>
      <ng-icon name="matUnfoldMoreOutline" size="16" />
    </div>
    @for (item of store.taxpayer()?.accounts; track $index) {
      <button
        class="tw-p-4 tw-py-3 flex-apart switch-btn"
        (click)="switchAccount(item.orgId)"
        [class]="{ active: item.orgId === store.activeAccount()?.orgId }"
      >
        <div>
          <h3 class="tw-font-semibold">{{ item.orgName }}</h3>
          <p class="help-text">{{ item.roleName }}</p>
        </div>
        @if (item.orgId === store.activeAccount()?.orgId) {
          <ng-icon name="matCheckOutline" size="20" />
        }
      </button>
    }
  </div>
</ng-template>

<ng-template #mfa>
  <div class="tw-p-8">
    <div class="tw-flex tw-justify-between tw-items-center tw-mb-2">
      <h1 class="tw-text-xl tw-font-semibold mb-1">Multi Factor Authentication</h1>
      <button type="button" class="btn-close" (click)="closeModal()"></button>
    </div>
    <p class="help-text tw-italic">
      Enabling multi factor authentication means adding an extra layer of security to your account. MFA required an
      additional verification step (Email OTP) during login to protect your account from unauthorized access
    </p>
    <div class="form-group tw-mt-4">
      <div class="tw-flex tw-gap-2 tw-items-center mt-2">
        <label class="mb-0">Enable MFA</label>
        <label class="radio">
          <input class="form-check-input" [formControl]="mfaValue" type="radio" value="yes" />
          Yes</label
        >
        <label class="radio tw-ml-2">
          <input class="form-check-input" [formControl]="mfaValue" type="radio" value="no" />
          No</label
        >
      </div>
    </div>
    <div class="mt-3">
      <button class="btn btn-sm btn-primary !tw-px-6 tw-float-end tw-ml-2" (click)="saveMfa()">Save</button>
      <button class="btn btn-sm btn-light tw-float-end" (click)="closeModal()">Cancel</button>
    </div>
  </div>
</ng-template>
